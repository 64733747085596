<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="InfoIcon" />
                Detail Variant
              </template>
              <info />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SettingsIcon" />
                Perawatan Berkala
              </template>
              <maintenance-scedule />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol, BCard,BTabs, BTab} from 'bootstrap-vue'

import Info from './include/Info.vue'
import MaintenanceScedule from './include/MaintenanceScedule.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs, 
    BTab,
    Info,
    MaintenanceScedule
  },
}
</script>
