<template>
  <b-card>
    <base-info
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :col_single="false"
    />

    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="6">
        <p>Sebaran Varian</p>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
          />

        </b-overlay>
      </b-col>
      <b-col cols="6">
        <template>
          <vue-apex-charts 
            ref="realtimeChart" 
            type="pie" 
            height="350" 
            :options="chartOptions" 
            :series="dataseries"
          />
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BCol, BRow, BTable, BOverlay} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable, 
    BOverlay
  },

  data() {
    return {
      dataurl:"/vehicle-variant",
      baseroute:"vehicle-variant",
      fields: [
        { key: 'code', label: 'Kode Varian', bold:true},
        { key: 'capacity', label: 'Kapasitas Mesin', type:'number' },
        { key: 'model', label: 'Model' },
        { key: 'transmission', label: 'Transmisi' },
        { key: 'manufacture.name', label: 'Pabrikan'},
        { key: 'axle_amount', label: 'Jumlah Roda' },
        { key: 'type.name', label: 'Tipe' },
        { key: 'tire_axle.name', label: 'Konfigurasi Axel' },
        { key: 'year', label: 'Tahun Produksi' },
        { key: 'tire_size.name', label: 'Ukuran ban' },
        { key: 'purchase_price', label: 'Harga beli Standar', type:'number' },
        { key: 'seat_amount', label: 'Tempat Duduk' },
        { key: 'fuel.name', label: 'BBM Standar' },
        { key: 'km_initial_check', label: 'Inisial KM Cek Pertama' },
        { key: 'bbm_capacity', label: 'Kapasitas BBM' },
        { key: 'km_next_check', label: 'KM Cek Berikutnya' },
        { key: 'fuel_consumption', label: 'KM /Lt' },
        { key: 'description', label: 'Keterangan' },
      ],
      dataurlvarian:"/vehicle-variant/"+this.$route.params.id+"/chart",
      fieldsTable: [
        { key: 'name', label: 'Region'},
        { key: 'count', label: 'Jumlah'},
      ],
      items:[],
      datalabel:[],
      dataseries:[],
      loading:false,
      chartOptions: {
        // colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.loading = true
      this.$http.get(this.dataurlvarian)
        .then(res  => {
          this.items = res.data.data
          for(let i = 0; i<res.data.data.length; i++){
            this.datalabel.push(res.data.data[i]['name'])
            this.dataseries.push(res.data.data[i]['count'])
            // this.dataseries.push(i+1)
          }
          this.$refs.realtimeChart.updateOptions({ labels: this.datalabel })
          // this.$refs.realtimeChart.updateOptions();
          
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
